import { sendToDataLayer } from '@utils';

const DOCUMENT_EVENT_CATEGORY = 'content_document_section';

export const trackDocumentPaymentType = (paymentType: string) => {
  sendToDataLayer(undefined, {
    event_category: DOCUMENT_EVENT_CATEGORY,
    event_action: 'payment_type',
    event_label: `${paymentType}`
  });
};
