import { FunctionComponent } from 'react';
import './style.scss';

const json = {
  th: {
    cash: [
      {
        src: '/assets/pages/help/id_card.svg',
        title: 'บัตรประชาชน หรือ Passport',
        description: (
          <>
            อายุไม่น้อยกว่า 21 ปี <br />
            และบัตรยังไม่หมดอายุ
          </>
        )
      },
      {
        src: '/assets/pages/help/driving_lic.svg',
        title: 'ใบขับขี่',
        description: (
          <>
            ยังไม่หมดอายุ และได้รับมา
            <br /> ไม่ต่ำกว่า 1 ปี
          </>
        )
      },
      {
        src: '/assets/pages/help/documents.svg',
        title: 'เอกสารประกอบอื่นๆ',
        description: 'อาจมีการขอเอกสารของผู้เช่าเพิ่มเติมจากบริษัทรถเช่า'
      }
    ],
    credit: [
      {
        src: '/assets/pages/help/id_card.svg',
        title: 'บัตรประชาชน หรือ Passport',
        description: (
          <>
            อายุไม่น้อยกว่า 21 ปี <br />
            และบัตรยังไม่หมดอายุ
          </>
        )
      },
      {
        src: '/assets/pages/help/driving_lic.svg',
        title: 'ใบขับขี่**',
        description: (
          <>
            ยังไม่หมดอายุ และได้รับมา
            <br /> ไม่ต่ำกว่า 1 ปี
          </>
        )
      },
      {
        src: '/assets/pages/help/creditcard.svg',
        title: 'บัตรเครดิต***'
      }
    ]
  },
  en: {
    cash: [
      {
        src: '/assets/pages/help/id_card.svg',
        title: 'National Identification Card or Passport',
        description: (
          <>
            The age of the individual should be at least 21 years old
            <br />
            and the document must be valid.
          </>
        )
      },
      {
        src: '/assets/pages/help/driving_lic.svg',
        title: 'Driver’s License',
        description: (
          <>
            Must be valid and held for a
            <br /> minimum of 1 year.
          </>
        )
      },
      {
        src: '/assets/pages/help/documents.svg',
        title: 'Other Supporting Documents*',
        description: 'Additional documentation may be requested by the rental company from the renter.'
      }
    ],
    credit: [
      {
        src: '/assets/pages/help/id_card.svg',
        title: 'National Identification Card or Passport',
        description: (
          <>
            The age of the individual should be at least 21 years old
            <br />
            and the document must be valid.
          </>
        )
      },
      {
        src: '/assets/pages/help/driving_lic.svg',
        title: 'Driver’s License',
        description: (
          <>
            Must be valid and held for a
            <br /> minimum of 1 year.
          </>
        )
      },
      {
        src: '/assets/pages/help/creditcard.svg',
        title: 'Credit Card***',
        description: 'A valid credit card with sufficient credit limit.'
      }
    ]
  }
};

interface DocumentBlockProps {
  src: string;
  title: string;
  description: string;
}
const DocumentBlock: FunctionComponent<DocumentBlockProps> = ({ src = '', title = '', description = '' }) => {
  return (
    <div className="document-req__item">
      <img src={src} height="35" width="48" alt="Content" />
      <h6 className="requirement-title">{title}</h6>
      <small>{description}</small>
    </div>
  );
};

interface DocumentRequirementProps {
  prefix: string;
  type: string;
  className?: string;
}
const DocumentRequirement: FunctionComponent<DocumentRequirementProps> = ({ prefix, type, className }) => {
  const content = json[prefix];
  const contentByPayment = type === 'credit' ? content['credit'] : content['cash'];

  return (
    <div className={`document-req ${className ? className : ''}`}>
      {contentByPayment.map((value: DocumentBlockProps) => (
        <div className="col-12 col-lg-4 mb-2 mb-lg-0" key={value.title}>
          <DocumentBlock src={value.src} title={value.title} description={value.description} />
        </div>
      ))}
    </div>
  );
};

export default DocumentRequirement;
