import { FunctionComponent } from 'react';

import HelpTabs from '@pages/help/components/tabs';
import DocumentRequirement from '@pages/help/components/document-req';

import { DefaultProps } from '../interface';
import { trackDocumentPaymentType } from './tracking';

import './documents.scss';

const json = {
  th: {
    cashTitle: 'เช่ารถที่ไม่ต้องใช้บัตรเครดิต',
    creditTitle: 'เช่ารถที่ต้องใช้บัตรเครดิต*',
    cash: [
      <>
        ทางบริษัทรถเช่าอาจเรียกเก็บค่าเช่าล่วงหน้าเพื่อล็อคคิวรถเป็นจำนวนเงิน <b>500-1000 บาท</b>
      </>,
      {
        เอกสารสำคัญ: []
      },
      {
        เอกสารประกอบอื่นๆ: [
          ['พนักงานบริษัท', 'สลิปเงินเดือนล่าสุด และบัตรพนักงาน'],
          [
            'สำหรับนักท่องเที่ยว / ชาวต่างชาติ',
            'ตั๋วเครื่องบิน หรือ ตั๋วโดยสารยานพาหนะต่าง ๆ ที่ท่านเดินทางมาถึงจังหวัดนั้น ๆ'
          ],
          [
            'เจ้าของกิจการ',
            'เอกสารที่ยืนยันตนว่าเป็นเจ้าของกิจการ เช่น ใบจดทะเบียนผู้เสียภาษี, หนังสือจดทะเบียนบริษัท'
          ],
          [
            'นักศึกษา',
            'บัตรนักศึกษา ใบรับรองสถานะนักศึกษา*',
            '*สำหรับผู้เป็นนักศึกษา จำเป็นต้องมีผู้ปกครองค้ำประกัน และต้องเดินทางมาเซ็นสัญญา ณ วันรับรถด้วย'
          ],
          [
            'ใช้รถระหว่างรอซ่อม',
            'เอกสารใบแจ้งซ่อม ใบเคลมประกัน ใบรับรถจากอู่หรือศูนย์บริการ*',
            '*ผู้ให้บริการบางรายอาจขอเอกสารเพิ่มเติม เช่น สลิปเงินเดือน หรือ เอกสารยืนยันว่าเป็นเจ้าของกิจการ'
          ]
        ]
      }
    ],
    credit: [
      {
        เอกสารสำคัญ: []
      },
      'หมายเหตุ',
      <>
        *เฉพาะรถที่มีสัญลักษณ์บัตรเครดิตเท่านั้น <br />
        **ในกรณีที่ผู้เช่า/ผู้ขับขี่ถือสัญชาติอื่นที่ไม่ใช่สัญชาติไทย จะต้องใบอนุญาตขับรถระหว่างประเทศ
        หรือใบอนุญาตขับรถของประเทศที่ทางประเทศไทยอนุญาตให้ใช้เพื่อการขับขี่ภายในประเทศไทยได้
        แต่ใบอนุญาตดังกล่าวจะต้องมีชื่อของผู้ขับขี่เป็นภาษาอังกฤษกำกับอยู่ <br />
        ***ต้องมีวงเงินในบัตรเพียงพอกับสำหรับค่าเช่าและค่ามัดจำเพื่อประกันความเสียหาย ที่บริษัทรถเช่ากำหนด
      </>
    ]
  },
  en: {
    cashTitle: 'Cash or Credit Card (at Pick-up)',
    creditTitle: 'Credit Card (on Drivehub Website)*',
    cash: [
      <>
        The car rental company may request an advance rental payment to secure the car reservation, typically ranging
        from <b>500 to 1000 Baht.</b>
      </>,
      {
        'Important Documents:': []
      },
      {
        'Other Supporting Documents:': [
          ['For Company Employees:', 'Latest paycheck stub and employee ID'],
          [
            'For Tourists and International Visitors:',
            'Proof of flight ticket or transportation to the respective province.'
          ],
          [
            'For Business Owners:',
            'Business registration documents, tax registration, or company registration certificate'
          ],
          [
            'For Students:',
            'Student ID card or Certificate of student status*',
            '*Students must have a guardian as a guarantor and must come in person to sign the agreement on the day of picking up the car.'
          ],
          [
            'For Using a Car During Repair:',
            'Repair notification document or Insurance claim document or Receipt of the car from the repair shop or service center*',
            '*Some providers may request additional documents such as pay stubs or documents confirming business ownership.'
          ]
        ]
      }
    ],
    credit: [
      {
        'Required Documents:': []
      },
      'Note:',
      <>
        *Applies only to vehicles with the credit card symbol. <br />
        <br />
        **In the case where the renter/driver holds a nationality other than Thai, an international driving permit or a
        driving license issued by a country authorized by Thailand for driving within the country is required. However,
        the mentioned license must be accompanied by an English translation of the {`driver's`} name.
        <br />
        <br />
        ***A sufficient credit limit on the card is necessary to cover both the rental fee and the security deposit as
        stipulated by the rental company.
      </>
    ]
  }
};

const Document: FunctionComponent<DefaultProps> = ({ prefix }) => {
  const text = json[prefix];

  return (
    <div className="document">
      <HelpTabs prefix={prefix} onExpand={(paymentType) => trackDocumentPaymentType(paymentType)}>
        {({ LocalTab, InterTab }) => {
          return (
            <>
              <LocalTab>
                <div className="mt-4">
                  <p className="mb-2"> {text.cash[0]}</p>
                  {Object.keys(text.cash[1]).map((key, i) => {
                    const title = key;
                    return (
                      <div className="mb-1 mb-lg-3" key={i}>
                        <h2 className="mb-3"> {title}</h2> <DocumentRequirement type="cash" prefix={prefix} />
                      </div>
                    );
                  })}
                  {Object.keys(text.cash[2]).map((key, i) => {
                    const title = key;
                    const values = text.cash[2][key];
                    return (
                      <div className="document-optional" key={i}>
                        <h2 className="mb-2"> {title}</h2>
                        <div className="d-flex flex-wrap">
                          {values.map((value: any, i: number) => (
                            <div className="col-12 col-lg-6 mb-2 document-optional__item" key={i}>
                              <div className="content p-3">
                                <h6> {value[0]}</h6>
                                <div>
                                  <img src="/assets/pages/help/how-to-book/green_check_icon.png" alt="Check mark" />
                                  {value[1]}
                                </div>
                                <p> {value[2]}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </LocalTab>
              <InterTab>
                <div className="mt-4">
                  {Object.keys(text.cash[1]).map((key, i) => {
                    const title = key;
                    return (
                      <div className="mb-1 mb-lg-3" key={i}>
                        <h2 className="mb-3"> {title}</h2> <DocumentRequirement type="credit" prefix={prefix} />
                      </div>
                    );
                  })}
                  <div className="box--note mt-3">
                    <h2 className="mb-2"> {text.credit[1]}</h2> <p className="mb-0"> {text.credit[2]}</p>
                  </div>
                </div>
              </InterTab>
            </>
          );
        }}
      </HelpTabs>
    </div>
  );
};

export default Document;
